import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';


export const AnimatedDiv = ({ children, delay }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,   // L'animation ne se déclenche qu'une fois
        threshold: 0.1,      // Le pourcentage de visibilité nécessaire pour déclencher l'animation
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ duration: 0.5, delay }}
        >
            {children}
        </motion.div>
    );
};
//être sûre que l'on revient en haut de la page en cliquant sur un bouton
export const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto", // Défilement en douceur
      });
    }, [pathname]); // Exécute cet effet à chaque changement de route
  
    return null;
  };
  