import "../../styles/footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import logo from '../../assets/Logo_footer.png'

export const Footer = () => {
    return (

        <footer >
            <div className="row justify-content-center mb-0 pt-3 pb-0 px-3">
                <div>
                    <div className="row row-2">
                        <div className="col-sm-4 text-md-left">
                            <div className="footer_col1">
                                <img src={logo}
                                    alt="WebInBlocks logo"
                                    className="logo_footer"
                                />
                            </div>

                        </div>

                        <div className="col-sm-3  my-sm-0 mt-5">
                            <ul className="list-unstyled">
                                <li>Océane Tapparel</li>
                                <li>078 815 77 85</li>
                                <li>oceane@webinblocks.ch</li>
                            </ul>
                        </div>

                        <div className="col-sm-5">
                            <ul className="list-unstyled">

                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=61563999410312"
                                        className="facebook social">
                                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                                    </a>
                                    <a href="https://www.instagram.com/webinblocks"
                                        className="insta social">
                                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                                    </a>

                                    <a href="https://wa.me/0788157785"
                                        className="whatsapp social">
                                        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/webinblocks/"
                                        className="linkedin social">
                                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-0 pt-0 row-1 mb-0  px-sm-3 px-2">
                <div>
                    <div className="row my-2 row-1 no-gutters">
                        <div className="col-sm-3 col-auto text-left">
                            <small>&#9400; 2024 - Made with ✨ by WebInBlocks  </small>
                        </div>
                        <div className="col-md-3 col-auto "></div><div className="col-md-3 col-auto"></div>
                    </div>
                </div>
            </div>
        </footer>

    )
}