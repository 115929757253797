import '../../styles/portfolio.css';
import { MainTitle, Subtitle } from "../ui/Titles";
import { Link } from 'react-router-dom';

import { ButtonNav } from '../ui/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWineGlassEmpty } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import { AnimatedDiv } from '../ui/Animations'


export const PortfolioModele = ({ objective_1, objective_2, objective_3, background, background_alt, video, site }) => {
    return (
        <div>
            <div className='porfolio_presentation'>
            <div className='porfolio_objective'>
                <MainTitle title="Objectifs du site web" />
                <AnimatedDiv delay={0}>
                <p className='portfolio_objective_text'> {objective_1} </p>
                <p className='portfolio_objective_text'>{objective_2} </p>
                <p className='portfolio_objective_text'>{objective_3} </p>
                
                </AnimatedDiv>  
                <div className='portfolio_site_visited'>
                    <ButtonNav router={site} title="Visiter le site" target="_blank" />
                </div>
</div>
            </div>
            <div>
                <img src={background} alt={background_alt} className='porfolio_img_background' />
             
                    <ReactPlayer className="portfolio_video"
                        url={video}
                      
                        controls
                        preload
                    />
               
            </div>
         
        </div>

    )
}