import '../styles/homeContainer.css'
import { MainTitle, Subtitle } from './ui/Titles'
import { ButtonNav } from './ui/Buttons'
import { ProjectHome, ProjectHome2 } from './ProjectPresentation';
import { Element } from 'react-scroll';
import { Link } from 'react-scroll';
import { AnimatedDiv } from './ui/Animations';
import img_services from '../assets/img_services.jpeg'
import icon_online_shop from '../assets/online-shop.png'
import icon_research from '../assets/research.png'
import icon_web_design from '../assets/web-design.png'
import cave_sonneur from '../assets/cave_sonneur.png'
import erwan_musique from '../assets/erwan_musique.png'
import process from '../assets/processus.png'
import Faq from 'react-faq-component';

export const PartOne = () => {

    return (
        <div className='part_one'>
          
            <div className='part_one_content'>
           
                <div className='text_part_one'>
                    <MainTitle title="Travaillons ensemble" />
                    <Subtitle subtitle="Je m'appelle Océane et je te propose de créer ton site web" />
                    <ButtonNav router='/contact' title="Me contacter" />
                </div>
            </div>
            <ButtonSection />
        </div>
    )
}

export const ButtonSection = () => {
    return (
        <div className='btn_section'>
            <Link className="btn_section_link" to="services" smooth={true} duration={500} offset={-70}> Services </Link>
            <Link className="btn_section_link" to="projects" smooth={true} duration={500} offset={-50}> Projets </Link>
            <Link className="btn_section_link" to="faq" smooth={true} duration={500} offset={-50}> FAQ </Link>
        </div>
    )
}


export const ImageCanape = () => {
    return (
        <div className="canape_services">
            <div className="intro_services">
                <AnimatedDiv delay={0}>
                    <img src={img_services} alt="canapé rouge qui introduit les services" className='img_services' />
                </AnimatedDiv>
            </div>
        </div>
    )
}

export const HomeSection = () => {
    return (
        <div>
            <Element name="services" className='section' id="services">
                <PartTwoServices />
            </Element>
            <Element name="projects" className='section' id="projects">
                <PartThreeProjects />
            </Element>

            <Element name="faq" className='section' id="faq">
                <PartFourthFAQ />
            </Element>
        </div>
    )
}

export const PartTwoServices = () => {

    const commerce = "Grâce à votre site web d'e-commerce innovant, nous transformons vos visiteurs en acheteurs fidèles. "
    const commerce2 = "Avec nos fonctionnalités intuitives, un design épuré et la gestion de stocks, nous vous aidons à offrir à vos clients une expérience d'achat agréable et ergonomique."
    const vitrine = "Votre site web est la vitrine de votre entreprise. Nous concevrons des solutions qui captiveront l'attention de vos visiteurs, éveilleront leur intérêt et les inciteront à agir."
    const vitrine2 = "A l'aide d'un site sur mesure et d'une navigation intuitive, mettons en valeur votre activité."
    const bi = "Ces dernières années, la data est au cours de nos préoccupations. Nous accompagnons votre entreprise dans l'élaboration de statistiques et indicateurs."
    const bi2 = "Transformez vos données brutes en informations exploitables grâce à la BI. "
    return (
        <div className="PartTwo_services">

            <MainTitle title="Services" />
            <div className='contain_services'>
                <div className='text_services'>
                    <AnimatedDiv delay={0.5}>
                        <img src={icon_online_shop} alt="icon de e-commerce" className="icon_services" />
                        <p className='title_service'> Plateforme de E - Commerce </p>
                        <p className='description_service'> {commerce} <br /> <br />  {commerce2} </p>
                    </AnimatedDiv>
                </div>

                <div className='text_services'>
                    <AnimatedDiv delay={0.5}>
                        <img src={icon_web_design} alt="icon de site web et responsive" className="icon_services" />
                        <p className='title_service'> Conception de sites web vitrines </p>
                        <p className='description_service'> {vitrine} <br /> <br /> {vitrine2} </p>
                    </AnimatedDiv>
                </div>

                <div className='text_services'>
                    <AnimatedDiv delay={0.5}>
                        <img src={icon_research} alt="icon de statistiques" className="icon_services" />
                        <p className='title_service'> Statistiques BI </p>
                        <p className='description_service'>  {bi} <br /> <br />  <br /> {bi2} </p>
                    </AnimatedDiv>
                </div>
            </div>
        </div>

    )
}
export const PartThreeProjects = () => {
    return (
        <div className="intro_projects">
            <div className="text_projects">
                <MainTitle title="Projets" />
            </div>
            <ProjectHome
                title="Cave du Sonneur"
                service="Site vitrine et e-commerce"
                description="Site dédié à la présentation de la cave du Sonneur et la vente de vin."
                router='/portfolio/cave-du-sonneur'
                image={cave_sonneur}
                alt="image de la cave du sonneur"
            />
             <ProjectHome
                title="Erwan musique"
                service="Site vitrine"
                description="Site dédié à la présentation de la musique de Erwan Tapparel, ses prestations et ses compositions."
                router='/portfolio/erwan-musique'
                image={erwan_musique}
                alt="image de la cave du sonneur"
            />
        </div>
    )
}


export const PartFourthFAQ = () => {
    const data = {
        rows: [
            {
                title: <p className='title_FAQ_size'> Quel est l'intérêt de créer un site web pour mon entreprise </p>,
                content:
                    <div className='description_FAQ'>
                        <p> Avoir un site web permet d'augmenter votre visibilité en ligne et de toucher un public plus large. C'est un outil essentiel pour renforcer votre présence en ligne.</p>
                    </div>
            },

            {
                title: <p className='title_FAQ_size'> Quelles sont les conditions de paiement ? </p>,
                content: (
                    <div className='description_FAQ'>
                        <p> Un premier acompte de 20% vous sera demandé lors de la signature du devis. Ce montant est non remboursable </p>
                        <p> Par la suite, un paiement échelonné est possible : </p>
                        <ul>
                            <li className='description_FAQ'> 20% Validation du prototype </li>
                            <li className='description_FAQ'> 40% Fin du développement </li>
                            <li className='description_FAQ'> 20% Livraison finale et mise en ligne du site </li>
                        </ul>

                    </div>
                )
            },
            {
                title: <p className='title_FAQ_size'> Quel est le processus de création d'un site web chez WebInBlocks ? </p>,
                content: (
                    <div className='description_FAQ'>
                        <div className="explanatory-text">
                            <ul>
                            <li className='description_FAQ'><p> <strong>Prise de contact</strong> </p> Je commence par un échange pour mieux comprendre vos besoins et attentes. Cela peut se faire par e-mail, téléphone, autour d’un café.</li>
                            <li className='description_FAQ'> <p> <strong>Etablir le devis et signature </strong> </p>  Je prépare un devis détaillé pour le projet que je vous soumettrai pour approbation et signature. </li>
                            <li className='description_FAQ'><p> <strong>Création d'un prototype </strong> </p> Je crée un modèle du site web, appelé prototype, pour visualiser la structure et le design. 

<br/> Ce prototype est soumis à validation pour nous assurer que nous prenons la bonne direction. </li>
                            <li className='description_FAQ'> <p> <strong>Développement </strong> </p>Je transforme le prototype en site web fonctionnel, en intégrant les fonctionnalités, contenu, et les éléments visuels prédéfinis en amont </li> 
                            <li className='description_FAQ'> <p> <strong>Echange et test </strong> </p> Tout au long de la phase de développement, nous organisons des sessions d’échanges pour discuter des progrès et des ajustements nécessaires.</li> 
                            <li className='description_FAQ'> <p> <strong>Déploiement et paiement </strong> </p>Je procède au déploiement en ligne. Le solde restant est réglé à ce moment-là</li> 

                               
                               
                                </ul>
                        </div>
                        <img src={process} alt="Processus élaboration d'un site web" className='img_process' />
                    </div>
                )

            },
            {
                title: <p className='title_FAQ_size'> Combien coûte la création d'un site web ? </p>,
                content: (
                    <div className='description_FAQ'>
                        <p> Le coût peut varier en fonction de plusieurs facteurs, notamment le type de site (vitrine ou e-commerce), la complexité du projet, le design souhaité et les fonctionnalités spécifiques requises . </p>
                        <p> Généralement chez WebInBlocks, nous procédons de la manière suivante : </p>
                        <ul>
                            <li className='description_FAQ'><p > <strong>Site vitrine </strong> </p> Utilisé pour présenter des informations sur une entreprise, ses produits ou services, sans fonctionnalités de commerce en ligne. Le coût moyen chez WebInBlocks commence à partir de CHF 2'000.- . Ce montant couvre la conception, le développement et les fonctionnalités de base telles que la page d'accueil et deux sous-pages. </li>
                            <li className='description_FAQ'> <p> <strong>Site e-commerce </strong> </p> Conçu pour vendre des produits en ligne avec des fonctionnalités comme la gestion de vos stocks, panier d'achat et paiement. Le côut démarre à environ CHF 4'000.- . Ce tarif peut varier selon les fonctionnalités demandées.</li>
                        </ul>
                        <p> Ces chiffres sont des estimations générales et peuvent varier en fonction des exigences spécifiques de chaque projet. Nous vous conseillons de nous contacter pour obtenir un devis personnalisé en fonction de vos besoins spécifiques. Chez WebInBlocks, nous nous engageons à offrir des solutions adaptées en fonction de vos besoins précis et de votre budget.</p>
                    </div>
                )
            },
           
          
            {
                title: <p className='title_FAQ_size'> A quoi sert la maintenance ? </p>,
                content: (
                    <div className='description_FAQ'>
                        <p> La mainteance assure le bon fonctionnement du site, sa sécurité et son évolution dans le temps.</p>
                   
                    </div>
                )
            },
        ]
    }

    return (
        <div className='FAQ_contain'>
            <div className='FAQ_title'>
                <MainTitle title="Questions fréquentes" />
            </div>
            <AnimatedDiv delay={0.5}>
                <Faq data={data} className="FAQ_content" />
            </AnimatedDiv>

        </div>
    )
}

