import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Button } from 'react-bootstrap';
import '../../styles/header.css';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import image1 from '../../assets/Image1.png'

export const Navigation = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const notFixedNavbarPaths = ["/"];
    const isNotFixed = notFixedNavbarPaths.includes(location.pathname);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Close menu on link click
    const handleLinkClick = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };
    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isMenuOpen]);

    return (
        <Navbar variant="dark" expand="lg" className={isNotFixed ? "" : "fixed-top"}>
            <Container>
                <Navbar.Brand href="/">
                <img src={image1}
                alt="WebInBlocks logo"
                style={{width:'150px', height:"auto"}}
                
                />
                
                </Navbar.Brand>

                { /* Btn display if screen is smaller */}
                <Button className="navbar-toggler" onClick={toggleMenu}> {isMenuOpen ? "\u2715" : "\u2630"} </Button>

                <Nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>

                    <Nav.Link href="/#services" onClick={handleLinkClick} offset={-70}> Services </Nav.Link>
                    <Nav.Link href="/#projects" onClick={handleLinkClick} offset={-70}> Projets </Nav.Link>
                    <Nav.Link href="/#faq" onClick={handleLinkClick} offset={-70}> FAQ </Nav.Link>
                    <Nav.Link href="/a-propos" onClick={handleLinkClick}> A propos </Nav.Link>
                    <Nav.Link href="/contact" onClick={handleLinkClick}> Contact </Nav.Link>
                    <Nav.Link href="/blog" onClick={handleLinkClick}> Blog </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}