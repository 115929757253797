import '../../styles/portfolio.css';
import { Article } from './Article_modele'
import Chapter1_img from '../../assets/Blog/background_chapter1.jpg'
import img_chapter1 from '../../assets/Blog/img_article_chapter1.jpg'

export const Chapter1_article = () => {
    const story_intro = (<>
        <p>📝  Il faisait froid ce soir-là, l’air était chargé d’humidité et de fraîcheur. Une douce odeur boisée et épicée flottait autour du marché. Un mélange d’épices, de vin chaud et de bois brûlé réchauffait les cœurs </p>

        <p>Pierre resserra son écharpe autour de son cou. Il venait de terminer sa journée dans la cave familiale qu’il dirigeait avec son père en Valais. Les affaires étaient stables, mais loin d’être florissantes.</p>

        <p>Sur le chemin qui menait au marché, une question lui trottait en tête : pourquoi les concurrents attirent-ils autant de touristes alors que nous peinons à nous faire connaître ?
        <br/>En effet, la cave du voisin affichait souvent complet. Les visites guidées étaient réservées plusieurs semaines à l’avance. À l’inverse, Pierre devait se battre pour remplir ses créneaux.</p>
        <p>La réponse se trouvait dans un mot qu’il ne maîtrisait pas : <strong>la digitalisation</strong></p>
    </>
    )

    const textOne = (<>
        <p>Dans un monde de plus en plus connecté, la digitalisation est devenue une nécessité pour les entreprises, quelle que soit leur taille ou leur secteur. Elle vise à rendre les entreprises plus agiles et performantes. L’un des piliers essentiels de cette transformation numérique est la création d’un site web adapté, réactif et optimisé.
        <br/>C'est un outil indispensable pour gagner en visibilité. </p>
        <p>La digitalisation a pour objectif de rendre une entreprise plus agile et plus performante sur son secteur. <br/><strong>L’une des approches et la proposition d’un site web adapté et réactif.</strong>
   </p>
   </>
    )

    const textTwo = (<>
    <p>Le Valais grâce à  ses paysages et son attrait touristique attire des milliers de visiteurs chaque année et regorge d’entrepreneurs locaux. Pourtant, un manque de visibilité sur internet prive de nombreuses entreprises de clients potentiels.</p>
    <p>Selon une étude Google, près de 81% des consommateurs recherchent un produits ou service en ligne avec de prendre une décision d’achat. Ces habitudes de consommations sont encore plus flagrantes chez les jeunes.</p>
    </>
    )

    const textThree = (<>
    <p> En Valais, de nombreuses entreprises reposent sur des savoir-faire artisanaux ou familiaux. Un site bien conçu valorise l’authenticité et rassure les clients potentiels. </p>
    <p>Des études menés par Stanford Web Credibility Research, indique que 75% des utilisateurs jugent la crédibilité d’une entreprise via la qualité de son site web. </p>
    </>
    )

    const conclusion_text = (<>
    <p> Grâce au référencement naturel (SEO), les entreprises apparaîssent en tête des résultats google.Facilitant ainsi la capacité et trouver de nouveaux clients sans avoir besoin d’intermédiaire.</p>
    <p><strong>Pour conclure</strong>, avoir un site web en Valais est encore plus essentiel car,</p>
    <p>📌 <strong>C’est une destination prisée</strong> qui chaque année amène de nombreux visiteurs. </p>
    <p>📌 <strong>C’est une régions montagneuses</strong> où les déplacements peuvent être contraigants. Un site permet aux clients de trouver facilement les informations sans avoir à se déplacer. </p>
     <p>📌 <strong>Un site internet valorise les produits locaux.</strong> Qu’il s’agisse de vins, de fromages ou d’atisanat, une bonne présence en ligne fait découvrir les richesses du Valais.</p>
      </>
      )
      
    return (
        <div>
            <Article
                img_article={Chapter1_img}
                legend_img_article="Le manque de visibilité des entreprises"
                date="27.11.2024"
                title_story="Chapitre 1 : Le manque de visibilité"
                story_intro={story_intro}
                title_subject="Pourquoi les PME ont besoin d’un site web en Valais ? "
                textOne={textOne}
                question1="Outil indispendable pour gagner en visibilité"
                texttwo={textTwo}
                img_parttwo={img_chapter1}
                question3="Un site bien structuré et adapté aux smartphones donne confiance"
                textthree={textThree}
                conclusion="Une opportunité pour se démarquer de la concurrence"
                conclusion_text={conclusion_text}
                teaser=" 📝 Dans le prochain chapitre, découvrez quel à été la première démarche de Pierre."
                link="https://credibility.stanford.edu/"
                etude="Stanford Web Credibility Research"
            />
        </div>
    )
}
