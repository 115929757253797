import '../../styles/portfolio.css';
import { PortfolioModele } from './Modele';
import cave_sonneur from '../../assets/cave_sonneur.png';
import background_sonneur from '../../assets/background_cave_sonneur.jpg';
import video_cave from '../../videos/cave_du_sonneur.mp4'

export const CaveSonneur = () => {
    return (
        <div>
            <PortfolioModele
            
                objective_1="Faire connaître l'entreprise et son histoire"
                objective_2="Faciliter l'achat en ligne des vins du Sonneur"
                objective_3="Augmenter la clientère grâce à un site web attractif et informatif"
                background={background_sonneur}
                background_alt="Cave du sonneur - vigne"
             video={video_cave}
             site={"https://erwantapparel.ch/cave"}
            />
        </div>
    )
}
