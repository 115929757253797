import "../../styles/article.css"
import border from "../../assets/border.png"

export const Article = ({ img_article, legend_img_article, title_story, date, story_intro, title_subject, textOne, question1, texttwo, img_parttwo, question3, textthree, conclusion,
    conclusion_text,teaser, link, etude
 }) => {
    return(
    <div className="article">
        <img src={img_article} className="article_img" alt={legend_img_article} />
       <img src={border} alt="Créer un site internet" className="border_article"></img>
       <div className="contain_article">
        <h1> {title_story} </h1>
        <div> {date} </div>
        <p> WebInBlocks _ Océane Tapparel </p>
        <div className="story_resume">
            <p> {story_intro} </p>
        </div>
        <div className="contain_article">
            <h2> {title_subject} </h2>
            <p> {textOne} </p>
            <div className="question1_contain">
                <div>
                    <h4> {question1} </h4>
                    <p> {texttwo} </p>
                </div>
                <img src={img_parttwo} alt="legend_img_parttwo" />
            </div>
            <h4> {question3} </h4>
            <p> {textthree} </p>
            <h4> {conclusion} </h4>
            <p> {conclusion_text} </p>
            <div className="teaser_contain">
                {teaser}
            </div>
            <div class="info-box">
  💡 Pour en savoir plus sur l'impact de la digitalisation, consultez cette article de <a href={link} target="_blank">{etude}</a>.
</div>
<br/>

        </div>
        </div>
    </div>
    )
}