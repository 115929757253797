import "../styles/projectPresentation.css"
import { ButtonNav } from './ui/Buttons'
import { AnimatedDiv } from "./ui/Animations"

export const ProjectHome = ({ title, service, description, router, image, alt }) => {
    return (
        <AnimatedDiv delay={0}>
        <div className="projectHome_disposition">
             
            <div className="projectHome_text">
                <p className="title_project"> {title} </p>
                <p className="service_project"> {service} </p>
                <p className="description_project"> {description} </p>
                <ButtonNav title="En savoir + " router={router} />
            </div>
            <div>
            <img src={image} alt={alt} className="image_project" />
            </div>
        </div>
        </AnimatedDiv>
    )

}

export const ProjectHome2 = ({ title, service, description, router, image, alt }) => {
    return (
        <AnimatedDiv delay={0}>
        <div className="projectHome_disposition">
        <div>
            <img src={image} alt={alt} className="image_project" />
            </div>
            <div className="projectHome_text">
                <p className="title_project"> {title} </p>
                <p className="service_project"> {service} </p>
                <p className="description_project"> {description} </p>
                <ButtonNav title="En savoir + " router={router} />
            </div>
           
        </div>
        </AnimatedDiv>
    )

}