import '../../styles/titles.css';
import { AnimatedDiv } from './Animations';

export const MainTitle = ({ title }) => {
    return (
        <AnimatedDiv delay={0}>
            <h1 className="main_title"> {title} </h1>
        </AnimatedDiv>
    )
}

export const Subtitle = ({ subtitle }) => {
    return (
        <AnimatedDiv delay={0.5}>
            <p className="subtitle"> {subtitle} </p>
        </AnimatedDiv>
    )
}
