import { motion } from 'framer-motion';
import { Chapter1_article } from '../../components/blog/Chapter';


export default function Chapitre1() {

    const pageTransition = {
        initial: { opacity: 0, y: 20, },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeInOut", }, },
        exit: { opacity: 0, y: -20, transition: { duration: 0.3, ease: "easeInOut", }, },
    };

    return (
        <div>
            <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}
            >
                <Chapter1_article />
            </motion.div>
        </div>


    )
}