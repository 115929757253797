import { motion } from 'framer-motion';
import { ScrollToTopButton } from '../../components/ui/Buttons';
import { CaveSonneur } from '../../components/portfolio/CaveSonneur';

const pageTransition = {
    initial: { opacity: 0, y: 20, },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeInOut", }, },
    exit: { opacity: 0, y: -20, transition: { duration: 0.3, ease: "easeInOut", }, }, 
};
export default function Porfolio_Cave_Sonneur() {
    return(
        <div>
      <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageTransition}
    >
            <CaveSonneur/>
            <ScrollToTopButton/>
            </motion.div>
        </div>
       
    )
}