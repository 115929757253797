import { MainTitle, Subtitle } from "./ui/Titles"
import contactimg from "../assets/contact.png"
import '../styles/contactContainer.css'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

export const ContactContainer = () => {
  return (
    <div className="contact_all">
      <div className="contact_container">
        <MainTitle title="Contact" />
        <img src={contactimg} alt="Création de site web via un ordinateur" className="img_contact" />
        <p className="text_contact"> Parlez-moi de votre projet par téléphone ou en remplissant le formulaire de contact. </p>
      </div>
      <div className="method_contact">
        <div>
          <div className="text-color">
            <Subtitle subtitle="Appelez-moi" />
          </div>
          <Subtitle subtitle="078 815 77 85" />

        </div>
        <div>
          <div className="text-color">
            <Subtitle subtitle="Fomulaire de contact" />
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: '',
    type: '',
  });

  // Shows alert message for form submission feedback
  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    // Hide alert after 5 seconds
    setTimeout(() => {
      setAlertInfo({ display: false, message: '', type: '' });
    }, 5000);
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    const { fistname, lastname, email, tel, type_of_project, budget, message } = data;
    try {
      setDisabled(true);


      setAlertInfo({
        display: true,
        message: 'Nous vous remercions pour votre demande. Je reviendrai rapidement vers vous : )',
        type: 'success', // ou 'danger', 'warning', etc. selon le contexte
      });

      // Define template params
      const templateParams = {
        from_firstname: fistname,
        from_name: lastname,
        from_email: email,
        message : message,
        tel : tel,
        type_of_project : type_of_project,
        budget:budget,
      };

      const serviceId = "service_b6juh48"
      const templateId = "template_kmntoml";
      const publicKey = "lKsHxchCE-KRHBNyJ"

      // Use emailjs to email contact form data
      await emailjs.send(
       serviceId,
       templateId,
        templateParams,
        publicKey
 
      );

      // Display success alert
      toggleAlert('Message envoyé avec succès', 'success');
    } catch (e) {
      console.error(e);
      // Display error alert
      toggleAlert('Oups quelques choses bloque.', 'danger');
    } finally {
      // Re-enable form submission
      setDisabled(false);
      // Reset contact form fields after submission
      reset();
    }
  };

  return (
    <div className='ContactForm'>
      <div className='container'>

        <div className='row'>
          <div className='col-12 text-center'>
            <div className='contactForm'>
              <form
                id='contact-form'
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                {/* Row 1 of form */}
                <div className='row formRow'>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='lastname'
                      {...register('lastname', {
                        required: {
                          value: true,
                          message: 'Nom',
                        },
                        maxLength: {
                          value: 30,
                          message: '30 caractères ou moins',
                        },
                      })}
                      className='form-control formInput'
                      placeholder='Nom'
                    ></input>
                    {errors.name && (
                      <span className='errorMessage'>
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='firstname'
                      {...register('firstname', {
                        required: {
                          value: true,
                          message: 'Prénom',
                        },
                        maxLength: {
                          value: 30,
                          message: '30 caractères ou moins',
                        },
                      })}
                      className='form-control formInput'
                      placeholder='Prénom'
                    ></input>
                    {errors.name && (
                      <span className='errorMessage'>
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className='row formRow'>

                  <div className='col'>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      })}
                      className='form-control formInput'
                      placeholder='Adresse mail'
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>
                        Entrez une adresse email valide
                      </span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className='row formRow'>

                  <div className='col'>
                    <input
                      type='number'
                      name='tel'
                      {...register('tel', {
                        required: true,

                      })}
                      className='form-control formInput'
                      placeholder='Numéro de téléphone'
                    ></input>
                    {errors.tel && (
                      <span className='errorMessage'>
                        Entrez un numéro de téléphone valide
                      </span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className='row formRow'>
                  <div className='col'>
                    <select

                      name='type_of_project'
                      {...register('type_of_project', {
                        required: {
                          value: true,
                          message: 'Sélectionnez un type',
                        },

                      })}
                      className='form-control formInput'
                    >
                      <option value=''>Type de projet </option> {/* Option par défaut */}
                      <option value='Nouveau site internet'>Création de site internet </option>
                      <option value='Refonte site internet'>Refonte d'un site internet</option>
                      <option value='subject3'>Autre </option>
                    </select>

                    {errors.subject && (
                      <span className='errorMessage'>
                        {errors.subject.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* Row 3 of form */}
                <div className='row formRow'>
                  <div className='col'>
                    <select

                      name='budget'
                      {...register('budget', {
                        required: {
                          value: true,
                          message: 'Votre budget',
                        },

                      })}
                      className='form-control formInput'
                    >
                      <option value=''>Votre budget </option> {/* Option par défaut */}
                      <option value='2000_5000 CHF '>2'000 à 5'000 </option>
                      <option value='5000_10000 CHF '>5'000 à 10'000 </option>

                    </select>

                    {errors.budget && (
                      <span className='errorMessage'>
                        {errors.budget.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* Row 3 of form */}
                <div className='row formRow'>
                  <div className='col'>
                    <textarea
                      rows={3}
                      name='message'
                      {...register('message', {
                        required: true,
                      })}
                      className='form-control formInput'
                      placeholder='Parlez-moi de votre projet, de vos attentes et de vos besoins'
                    ></textarea>
                    {errors.message && (
                      <span className='errorMessage'>
                        Entrez votre message
                      </span>
                    )}
                  </div>
                </div>
                {/* Row for consent checkbox */}
                <div className='row formRow'>
                  <div className='col'>
                    <div className='form-check'>
                      <input
                        type='checkbox'
                        name='consent'
                        {...register('consent', {
                          required: {
                            value: true,
                            message: "Merci d'accepter les conditions",
                          },
                        })}
                        className='form-check-input'
                        id='consentCheckbox'
                      />
                      <label className='form-check-label' htmlFor='consentCheckbox'>
                        J'accepte que mes données personnelles soient collectées et utilisées par WebInBlocks afin de répondre à ma demande.
                      </label>
                    </div>
                    {errors.consent && (
                      <span className='errorMessage'>
                        {errors.consent.message}
                      </span>
                    )}
                  </div>
                </div>
                <button
                  className='btn_navigation'

                  disabled={disabled}
                  type='submit'
                >
                  Envoyer la demande
                </button>
              </form>
            </div>
          </div>
        </div>

      </div >
      {
        alertInfo.display && (
          <div
            className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
            role='alert'
          >
            {alertInfo.message}
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='alert'
              aria-label='Close'
              onClick={() =>
                setAlertInfo({ display: false, message: "Nous vous remercions pour votre demande. Je reviendrai rapidement vers vous : ) ", type: '' })
              } // Clear the alert when close button is clicked
            ></button>
          </div>
        )
      }
    </div >
  );
};