import me from "../assets/me.jpg"
import border from "../assets/border.png"
import chapter1 from "../assets/Blog/Chapter1.png"
import "../styles/blog.css"
import { PostStructure } from "./ui/Post"
export const BlogContainer = () => {
    const text = 
    <p>Il faisait froid ce soir-là, l’air était chargé d’humidité et frais. Une douce odeur boisé et épicé flottait autour du marché. Un mélange d’épice, de vin chaud et de bois brûlé réchaufaient les coeurs.
<p/>
    Pierre resserra son écharpe autour du corps. Il venait de terminer sa journée dans la cave familiale qu’il dirigeait avec son père en Valais. Les affaires étaient stables, mais loin d’être florissantes.
    Sur le chemin qui menait au marché ...</p>

    return(
        <div className="blog_contain">
            <div className="blog_header">
                <div className="title">
                <h1> WebInBlocks </h1>               
                <h5> Le blog </h5>
                </div>
                <p className="paragraph"> Chaque mois, découvrez une nouvelle partie des aventures de Pierre : un entrepreneur passionné du Valais, en quête de succès malgré les obstacles. Suivez son parcours, ses défis et ses victoires ! </p>
       
            </div>

            <img src={border} alt="color website" className="border_blog"></img>
           <h4 className="post_style"> Derniers chapitres en ligne </h4>
           <div>
           <PostStructure 
           img={chapter1} img_title={"manque de visibilité"}
           title="27.11.2024 - Chapitre 1 : le manque de visibilité" 
           text={text}
           routerOfChapter="/blog/site-web-pme"
           />
           
           </div>

        </div>
    )
}