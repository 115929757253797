import { Link } from 'react-router-dom';
import '../../styles/buttons.css'
import { AnimatedDiv } from './Animations';
import { useState} from 'react';
import { motion } from 'framer-motion';
import { FaArrowUp } from 'react-icons/fa';

export const ButtonNav = ({ router, title, target, className }) => {
    return(
        <AnimatedDiv delay={0.5}>
        <a href={router} target={target} className={`btn_navigation ${className || ''}`} >
            {title}
        </a>
        </AnimatedDiv>
    )
}
export const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'  // Utilise un défilement fluide
      });
    };
  
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    // Ajoutez un écouteur d'événements pour déterminer quand afficher le bouton
    window.addEventListener('scroll', handleScroll);
  
    return (
      <motion.button
        className={`scroll-to-top ${isVisible ? 'show' : 'hide'}`}
        onClick={scrollToTop}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <FaArrowUp/>
      </motion.button>
    );
  };