import '../../styles/portfolio.css';
import { PortfolioModele } from './Modele';
import background_music from '../../assets/background_music.jpg';
import video_musique from '../../videos/Erwan_musique.mp4'

export const ErwanMusique = () => {
    return (
        <div>
            <PortfolioModele
            
                objective_1="Découvrir le musicien Erwan Tapparel"
                objective_2="Présenter ses prestations, projets et compositions"
                objective_3="Augmenter la clientère grâce à un site web attractif et informatif"
                background={background_music}
                background_alt="Erwan Musique"
             video={video_musique}
             site={"https://erwantapparel.ch/musique"}
            />
        </div>
    )
}
